import React from "react";


const Form = (props) => {


  return (
    <div className="px-5">
      <div class="elfsight-app-c97fb065-ab86-4e7d-aa3b-0177db35050e"></div>
    </div >
  );
};

export default Form;


// variable del formulario:
// tname=""
// tlname=""
// temail=""
// tphone=""
// taddress=""
// ttypeservice=""
// tbestway=""
// tcheckphone=""
// tcheckcorreo=""
// tmessage=""
// tbutton=""
// isenglish="true"
