import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";

function HeroSection() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <div className="w-full h-auto flex md:flex-row flex-col-reverse  relative">
        <div className="w-[45%] h-auto absolute bgImgHero z-10 hidden md:block"></div>
        <div className="md:w-1/2 w-full md:block hidden bg-black">
          <div className="z-20 relative w-4/5 mx-auto h-[80vh] md:h-[100vh] ">
            <div className="w-full h-full pb-[15%] md:pb-[10%] flex flex-col justify-end items-center bg-black">
              <h1 className="titleColorFt1 text-center text-[56px]">
                {rpdata?.dbSlogan?.[1].slogan}
              </h1>
              <div className="flex justify-center md:block">
                <ButtonContent />
              </div>
            </div>
            <div className="w-1/2"></div>
          </div>
        </div>
        <div className="md:w-1/2 w-full ">
          <div className="pic-wrapper">
            <figure
              className="pic-1"
              style={{ background: `url("${rpdata?.gallery?.[5]}")` }}
            ></figure>
            <figure
              className="pic-2"
              style={{ background: `url("${rpdata?.gallery?.[4]}")` }}
            ></figure>
            <figure
              className="pic-3"
              style={{ background: `url("${rpdata?.gallery?.[15]}")` }}
            ></figure>
            <figure
              className="pic-4"
              style={{ background: `url("${rpdata?.gallery?.[6]}")` }}
            ></figure>
            <div className="z-10 relative w-4/5 mx-auto h-[100vh]">
              <div className="w-full h-full pb-[15%] md:pb-[10%] flex flex-col justify-end items-center">
                <h1 className="text-white text-center md:hidden block ">
                  {rpdata?.dbSlogan?.[4].slogan}
                </h1>
              </div>
              <div className="w-1/2"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
