import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import BaseLayout from "../components/global/BaseLayout";
import ValuesOne from "../components/values/Values_3";
import BlockPrincipal from "../components/block/Block_7";

import Block from "../components/block/Block_11";

function About() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Sobre Nosotros">
      <div className="md:max-w-full w-full">
        <TransparentHeader
          headertitle="Sobre Nosotros"
          Subheader="Sobre Nosotros"
          bgimg={`${rpdata?.stock?.[0]}`}
        />
        <BlockPrincipal
            title={'un poco sobre nosotros'}
            text={rpdata?.dbAbout?.[0]?.text}
            image={rpdata?.gallery?.[1]}
            listsAbout
          />
        <ValuesOne
        image1={rpdata?.stock?.[1]}
        image2={rpdata?.stock?.[0]}

        />
        <Block
        title={rpdata?.dbSlogan?.[4]?.slogan}
        text={rpdata.dbAbout?.[1]?.text}
        image1={rpdata?.gallery?.[3]}
        image2={rpdata?.gallery?.[4]}
        />

      </div>
    </BaseLayout>
  );
}

export default About;
