import React from "react";

const TransparentHeader = ({ headertitle, bgimg, Subheader }) => {
  return (
    // <div
    //   className="breadcrumb-area"
    //   style={{ backgroundImage: `url("${bgimg}")` }}
    // >
    //   <div className="text-center flex flex-col justify-end w-full h-full pb-[10%] relative">
    //     <h1 className="text-white">{headertitle}</h1>
    //     {
    //       headertitle === Subheader ?
    //         <h6 className="text-white">Home - {Subheader}</h6>
    //         :
    //         <h6 className="text-white">{Subheader}</h6>
    //     }
    //   </div>
    // </div>
    <div className="w-full h-auto flex md:flex-row flex-col-reverse relative">
      <div className="w-[45%] h-auto absolute bgImgHero z-10 hidden md:block"></div>
      <div className="md:w-1/2 w-full md:h-[85vh] h-[20vh] md:text-start text-center flex items-center px-10 z-20">
        <h1 className="text-black pt-0 md:pt-14">{headertitle}</h1>
      </div>
      <div className="md:w-1/2 w-full md:h-[85vh] h-[60vh] bg-cover bg-center"
        style={{ backgroundImage: `url("${bgimg}")` }}
      ></div>
    </div>
  );
};

export default TransparentHeader;
